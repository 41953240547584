import {
  Modal,
  Row,
  Col,
  Input,
  Select,
  Button,
  Checkbox,
  Form,
  Typography,
  Upload,
  Popconfirm,
} from "antd"
import { useForm } from "antd/es/form/Form"
import { useEffect, useRef, useState } from "react"
import { ArticleCategory, articleService } from "../Services/Article.service"
import { UploadOutlined } from "@ant-design/icons"
import { hostedImage } from "../Helpers/Image.helper"
import { bytesToKilobytes } from "../Helpers/File.helper"

export const ArticleModal = ({ isShowing, onClose, article }: any) => {
  const articleFormRef: any = useRef({})
  const [articleForm] = useForm()

  const { Text } = Typography

  const [articleCategories, setArticleCategories] = useState<ArticleCategory[]>(
    []
  )
  const [articleModalLinks, setArticleModalLinks] = useState<any[]>([])
  const [premium, setPremium] = useState<boolean>(article?.premium ?? false)
  const [draft, setDraft] = useState<boolean>(article?.draft ?? true)
  const [imageChanged, setImageChanged] = useState<boolean>(false)

  const defaultHeaderImageURL =
    (article?.headerImageURL ?? "").length > 0
      ? [
          {
            url: hostedImage(article?.headerImageURL ?? "", "article"),
            uid: "article1",
            name: "",
          },
        ]
      : []

  /**
   * Fetch article categories on mount
   */
  useEffect(() => {
    fetchArticleCategories()

    if (article) {
      article.articleCategories = article.articleCategories.map(
        (category: any) => category.id
      )
      articleForm.setFieldsValue(article)
      setPremium(article.premium)
      setDraft(article.draft)
      setArticleModalLinks(article.articleLinks)
    } else {
      articleForm.resetFields()
    }
  }, [article])

  /**
   * Fetch article categories
   * @returns {Promise<void>}
   */
  async function fetchArticleCategories() {
    try {
      const articleCategories: ArticleCategory[] =
        await articleService.getArticleCategories()

      setArticleCategories(articleCategories)
    } catch (error) {}
  }

  async function saveArticle(values: any) {
    const article = {
      ...values,
      premium,
      draft,
      articleLinks: articleModalLinks,
    }

    const success = await articleService.updateArticle(article, imageChanged)
    if (success) {
      onClose()
    }
  }

  function addArticleModalLink() {
    const links = [...articleModalLinks]
    links.push({ url: "", label: "", order: articleModalLinks.length + 1 })
    setArticleModalLinks(links)
  }

  function setArticleModalLink(key: string, value: string, index: number) {
    const links = [...articleModalLinks]
    links[index][key] = value
    setArticleModalLinks(links)
  }

  function removeArticleLink(index: number) {
    const links = [...articleModalLinks]
    links.splice(index, 1)
    setArticleModalLinks(links)
  }

  function handleCheckboxTick(key: string, value: boolean) {
    if (key === "draft") {
      setDraft(value)
    } else if (key === "premium") {
      setPremium(value)
    }
  }

  async function handleBeforeUpload(key: string, event?: any) {
    setImageChanged(true)
    return false
  }

  function confirmDeletion() {
    articleService.deleteArticle(article.id)
    onClose()
  }

  return (
    <Modal
      title="Opret artikel"
      open={isShowing}
      onCancel={onClose}
      onOk={() => articleFormRef.current.submit()}
      width={700}
    >
      <Form
        layout="vertical"
        onFinish={saveArticle}
        autoComplete="off"
        form={articleForm}
        ref={articleFormRef}
        initialValues={{
          premium: article?.premium,
          draft: article?.draft,
          articleLinks: article?.articleLinks ?? [],
          articleCategories:
            article?.articleCategories?.map((category: any) => category.id) ??
            [],
        }}
      >
        <Form.Item name="id" hidden={true}>
          <Input />
        </Form.Item>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="title"
              label="Titel"
              rules={[{ required: true, message: "Feltet skal udfyldes" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="articleCategories" label="Kategorier">
              <Select placeholder="Vælg artikelkategorier" mode="multiple">
                {articleCategories.map((category) => (
                  <Select.Option value={category.id}>
                    {category.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginBottom: 16 }}>
          <Col span={24}>
            <Form.Item
              name="content"
              label="Indhold"
              rules={[{ required: true, message: "Feltet skal udfyldes" }]}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16} style={{ marginBottom: 16 }}>
          <Col span={24}>
            <Button onClick={addArticleModalLink}>Tilføj link</Button>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            {articleModalLinks?.map((link: any, index: number) => (
              <Row gutter={16} style={{ marginBottom: 16 }} key={index}>
                <Col span={7}>
                  <Input
                    placeholder="Tekst"
                    value={link.label}
                    onChange={(e) =>
                      setArticleModalLink("label", e.target.value, index)
                    }
                  />
                </Col>
                <Col style={{ flexGrow: 1 }}>
                  <Input
                    placeholder="Link"
                    value={link.url}
                    onChange={(e) =>
                      setArticleModalLink("url", e.target.value, index)
                    }
                  />
                </Col>
                <Col>
                  <Button danger onClick={() => removeArticleLink(index)}>
                    Fjern link
                  </Button>
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
        <Row>
          <Form.Item label="Billede" name="headerImageURL">
            <Upload
              listType="picture"
              maxCount={1}
              beforeUpload={() => handleBeforeUpload("primaryImageURL")}
              onChange={(e) => handleBeforeUpload("primaryImageURL", e)}
              defaultFileList={defaultHeaderImageURL}
            >
              <Button icon={<UploadOutlined />}>Upload (Max: 1)</Button>
            </Upload>
          </Form.Item>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="premium">
              <>
                <Checkbox
                  checked={premium}
                  onChange={(e) =>
                    handleCheckboxTick("premium", e.target.checked)
                  }
                />
                <Text style={{ marginLeft: 8 }}>Kun for premium brugere</Text>
              </>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="draft">
              <>
                <Checkbox
                  checked={draft}
                  onChange={(e) =>
                    handleCheckboxTick("draft", e.target.checked)
                  }
                />
                <Text style={{ marginLeft: 8 }}>Kladde?</Text>
              </>
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ marginTop: 40 }}>
          <Col span={24}>
            <Popconfirm
              title="Slet artikel"
              description="Vil du slette denne artikel?"
              onConfirm={confirmDeletion}
              onCancel={() => {}}
              okText="Ja"
              cancelText="Nej"
            >
              <Button danger type="dashed">
                Slet artikel
              </Button>
            </Popconfirm>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
