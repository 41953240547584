import {create} from "zustand";
import { persist } from "zustand/middleware";
import { Recipe } from "../Models/Recipe";

interface RecipeStore {
  recipes: Recipe[];
  setRecipes: (recipes: Recipe[]) => void;
}
export const useRecipeStore = create<RecipeStore>()(
  persist(
    (set) => ({
      recipes: [],
      setRecipes: (recipes: Recipe[]) => set({ recipes }),
    }),
    {
      name: "recipe-storage",
    }
  )
);
