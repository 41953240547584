import { useEffect, useMemo, useRef, useState } from "react"
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Table,
  Input,
  Checkbox,
  message,
} from "antd"
import { Ingredient } from "../Models/Ingredient"
import { ingredientService } from "../Services/Ingredient.service"
import { CheckOutlined } from "@ant-design/icons"
import { useForm } from "antd/es/form/Form"

export function IngredientsPage() {
  const [updateFormInstance] = useForm()

  const createForm: any = useRef(null)
  const updateForm: any = useRef(null)

  const [dataSource, setDataSource] = useState<Ingredient[]>([])

  const [isShowingCreateModal, setIsShowingCreateModal] = useState(false)
  const [isShowingUpdateModal, setIsShowingUpdateModal] = useState<
    number | null
  >(null)
  const [isFetching, setIsFetching] = useState(false)
  const [filterQuery, setFilterQuery] = useState("")

  const filteredDatasource = useMemo(() => {
    return dataSource.filter((ingredient) =>
      ingredient.name.toLowerCase().includes(filterQuery.toLowerCase())
    )
  }, [dataSource, filterQuery])

  const activeUpdateModel = useMemo(
    () =>
      dataSource.find((ingredient) => ingredient.id === isShowingUpdateModal),
    [dataSource, isShowingUpdateModal]
  )

  useEffect(() => {
    fetchData()
  }, [])

  async function fetchData() {
    setIsFetching(true)

    try {
      const ingredients: Ingredient[] = await ingredientService.getIngredients()
      setDataSource(ingredients)
      setIsFetching(false)
    } catch (error) {
      setIsFetching(false)
    }
  }

  const columns = [
    {
      title: "Navn",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Synlig",
      dataIndex: "visible",
      key: "visible",
      align: "center" as any,
      width: 80,
      render: (visible: boolean) => (visible ? <CheckOutlined /> : ""),
    },
    {
      title: "Selvoprettet",
      dataIndex: "readOnly",
      key: "readOnly",
      align: "center" as any,
      width: 80,
      render: (readOnly: boolean) => (!readOnly ? <CheckOutlined /> : ""),
    },
  ]

  /**
   * Update modal
   */

  async function openUpdateModal(id: number) {
    setIsShowingUpdateModal(id)

    const activeIngredient = dataSource.find(
      (ingredient) => ingredient.id === id
    )

    updateFormInstance.setFieldsValue(activeIngredient)
  }

  function closeUpdateModal() {
    setIsShowingUpdateModal(null)
    updateForm.current.resetFields()
  }

  /**
   * Create modal
   */
  async function openCreateModal() {
    setIsShowingCreateModal(true)
  }

  function closeCreateModal() {
    setIsShowingCreateModal(false)
    createForm.current.resetFields()
  }

  async function handleCreateAction() {
    const form = createForm.current
    form.submit()
  }

  async function handleUpdateAction() {
    const form = updateForm.current
    form.submit()
  }

  async function onUpdateSubmit(values: any) {
    const success = await ingredientService.updateIngredient({
      ...values,
      id: activeUpdateModel?.id,
    })

    if (!!success) {
      message.success(`"${values.name}" er blevet opdateret`)

      closeUpdateModal()
      fetchData()
    }
  }

  function onUpdateSubmitFailed(errorInfo: any) {
    console.error("Failed:", errorInfo)
  }

  async function onCreateSubmit(values: any) {
    const success = await ingredientService.createIngredient(values)

    if (!!success) {
      message.success(`"${values.name}" er blevet oprettet`)

      closeUpdateModal()
      fetchData()
    }
  }

  function onCreateSubmitFailed(errorInfo: any) {
    console.error("Failed:", errorInfo)
  }

  async function onDelete() {
    const id = activeUpdateModel?.id

    if (!id) return

    const success = await ingredientService.deleteIngredient(id)

    if (!!success) {
      closeUpdateModal()
      fetchData()

      message.success(`"${activeUpdateModel?.name}" er blevet slettet`)
    }
  }

  const handleFilterQuerychange = (event: any) => {
    setFilterQuery(event.target.value)
  }

  return (
    <>
      <Row>
        <Col flex={1}>
          <Input
            placeholder="Find ingrediens"
            value={filterQuery}
            onChange={handleFilterQuerychange}
          />
        </Col>
        <Col offset={1}>
          <Button type="primary" onClick={openCreateModal}>
            Opret Ingrediens
          </Button>
        </Col>
      </Row>

      <Row style={{ marginTop: "16px" }}>
        <Col span={24}>
          <Table
            bordered={true}
            dataSource={filteredDatasource}
            columns={columns}
            loading={isFetching}
            rowKey={(record: any) => record.id.toString()}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  openUpdateModal(record.id as any)
                },
              }
            }}
          />
        </Col>
      </Row>

      {/* Update modal */}

      <Modal
        title={`Rediger "${activeUpdateModel?.name}"`}
        open={!!isShowingUpdateModal}
        okText="Opdater"
        cancelText="Annuller"
        onCancel={closeUpdateModal}
        footer={[
          <Row>
            <Button
              key="delete"
              danger
              onClick={() => {
                Modal.confirm({
                  title: "Er du sikker?",
                  content: `Er du sikker på, at du vil slette "${activeUpdateModel?.name}"?`,
                  okText: "Ja, jeg er sikker",
                  cancelText: "Annuller",
                  onOk: () => {
                    onDelete()
                  },
                })
              }}
            >
              Slet
            </Button>
            <span key="spacer" style={{ flex: 1 }} />
            <Button key="cancel" onClick={closeUpdateModal}>
              Annuller
            </Button>
            <Button key="update" type="primary" onClick={handleUpdateAction}>
              Opdater
            </Button>
          </Row>,
        ]}
      >
        <Form
          form={updateFormInstance}
          ref={updateForm}
          name="update-data"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          onFinish={onUpdateSubmit}
          onFinishFailed={onUpdateSubmitFailed}
          layout="vertical"
          autoComplete="off"
          initialValues={activeUpdateModel}
        >
          {/* Name */}
          <Form.Item
            label="Navn"
            name="name"
            rules={[{ required: true, message: "Feltet skal udfyldes" }]}
          >
            <Input />
          </Form.Item>

          {/* Visible */}
          <Form.Item name="visible" valuePropName="checked">
            <Checkbox>Synlig</Checkbox>
          </Form.Item>
        </Form>
      </Modal>

      {/* Create modal */}
      <Modal
        title="Opret ingrediens"
        open={isShowingCreateModal}
        okText="Opret"
        cancelText="Annuller"
        onOk={handleCreateAction}
        // confirmLoading={confirmLoading}
        onCancel={closeCreateModal}
      >
        <Form
          ref={createForm}
          name="create-data"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          onFinish={onCreateSubmit}
          onFinishFailed={onCreateSubmitFailed}
          layout="vertical"
          autoComplete="off"
          initialValues={{
            visible: true,
          }}
        >
          {/* Name */}
          <Form.Item
            label="Navn"
            name="name"
            rules={[{ required: true, message: "Feltet skal udfyldes" }]}
          >
            <Input />
          </Form.Item>

          {/* Visible */}
          <Form.Item name="visible" valuePropName="checked">
            <Checkbox>Synlig</Checkbox>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
