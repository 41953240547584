import { message } from "antd";
import { initializeApp } from "firebase/app";
import { User, UserCredential, getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { Firestore, getFirestore } from "firebase/firestore";

interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  projectId: string;
  messagingSenderId: string;
  measurementId: string;
}
class FirebaseService {
  private config: FirebaseConfig;
  private app;
  public auth;
  public db: Firestore
  public user: any

  constructor() {
    this.config = {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY!,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN!,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID!,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID!,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID!,
    };

    this.app = initializeApp(this.config);
    this.auth = getAuth(this.app);
    this.db = getFirestore(this.app)
  }

  async login(email: string, password: string) {

    try {
      const user: UserCredential = await signInWithEmailAndPassword(this.auth, email, password)
      const claims: string[] = (await user.user.getIdTokenResult()).claims.custom_claims

      if(claims.includes("ADMIN") || claims.includes("DEV")) {
        return {user}
      }

      await this.auth.signOut();
      message.error("Kunne ikke logge ind!");
      return {Error}

    } catch (error) {
      await this.auth.signOut();
      message.error("Brugernavn eller adgangskode er forkert")
      return {error}
    }
  }

  async logout() {
    try {
      await this.auth.signOut();
    } catch (error) {
      console.error(error);
    }
  }

  async getCurrentUser() {
    try {
      const user = this.auth.currentUser;
      return user;
    } catch (error) {
      console.error(error);
    }
  }
}

export const firebaseService = new FirebaseService();
