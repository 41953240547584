import { api } from "./API.service";
import { UserStatus } from "../Models/UserStatus";

class UserService {
  

  async getUserStatus(): Promise<UserStatus> {
    try {
      const response = await api.makeRequest(`/admin/user/status`);

      return response.content;
    } catch (error) {
      console.error("Failed to fetch User Status:", error);
      throw error;
    }
  }

}

export const userService = new UserService();
