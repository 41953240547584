import {
  Button,
  Col,
  Input,
  Modal,
  Row,
  Space,
  Typography,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { termsService } from "../Services/Terms.service";

export const TermsPage = () => {
  const [showCurrentTerms, setShowCurrentTerms] = useState(false);
  const [showDraftTerms, setShowDraftTerms] = useState(false);
  const [currentPublishedTerms, setCurrentPublishedTerms] = useState<any>(null);
  const [currentDraftTerms, setCurrentDraftTerms] = useState<any>(null);
  const [closeDraftModal, setCloseDraftModal] = useState(false);
  const [draftExists, setDraftExists] = useState<boolean>(false);

  const [draftedTerms, setDraftedTerms] = useState<any>(null);

  useEffect(() => {
    getCurrentTerms();
    getCurrentDraftTerms();
    getDraftExists();
  }, []);

  async function getCurrentTerms() {
    const terms = await termsService.getCurrentPublishedTerms();
    setCurrentPublishedTerms(terms);
  }

  async function getCurrentDraftTerms() {
    const terms = await termsService.getCurrentDraftTerms();
    setCurrentDraftTerms(terms);
    setDraftedTerms(terms);
  }

  async function getDraftExists() {
    const exists = await termsService.draftExists();
    setDraftExists(exists);
  }

  const { Text } = Typography;

  const notice =
    "Ændring af betingelser vil medføre at alle app brugere vil blive mødt af en besked om ændring af betingelserne. Brugere vil blive bedt om at acceptere de nye betingelser for at kunne fortsætte med at bruge appen.";
  const notice2 =
    "Det er kun muligt at have én version af betingelserne publiceret. Når nye betingelser publiceres, vil de tidligere betingelser blive arkiveret. Dette betyder at det ikke er muligt at rulle tilbage til tidligere betingelser.";

  async function saveDraft(newTerms: any) {
    if (newTerms?.text?.length < 1 || !newTerms?.text) {
      message.error("Betingelserne kan ikke være tomme");
      return;
    }

    await termsService.updateDraftTerms(draftedTerms);
    setCloseDraftModal(true);
    getDraftExists();
    setDraftedTerms(null);
    setShowDraftTerms(false);
  }

  async function saveAndPublish(newTerms: any) {
    if (newTerms?.text?.length < 1 || !newTerms?.text) {
      message.error("Betingelserne kan ikke være tomme");
      return;
    }

    await termsService.publishDraftTerms(draftedTerms);
    setCloseDraftModal(true);
    getDraftExists();
    setShowDraftTerms(false);
  }

  return (
    <>
      <div>
        <Row>
          <Col span={24}>
            <Text style={{ fontSize: 24 }}>Bemærk</Text>
          </Col>
        </Row>
        <Row style={{ marginBottom: 16 }}>
          <Col span={24}>
            <Text>{notice}</Text>
          </Col>
        </Row>
        <Row style={{ marginBottom: 16 }}>
          <Col span={24}>
            <Text>{notice2}</Text>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col>
            <Button
              type="primary"
              onClick={async () => {
                await getCurrentTerms();
                setShowCurrentTerms(true);
              }}
            >
              Vis aktuelle betingelser
            </Button>
          </Col>
          <Col>
            <Button
              onClick={async () => {
                await getCurrentDraftTerms();
                setShowDraftTerms(true);
              }}
            >
              Vis udkast til nye betingelser
            </Button>
          </Col>
        </Row>
      </div>

      <Modal
        title="Aktuelle betingelser"
        open={showCurrentTerms}
        onCancel={() => {
          setShowCurrentTerms(false);
        }}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              setShowCurrentTerms(false);
            }}
          >
            Luk
          </Button>,
        ]}
      >
        <div
          style={{
            padding: 16,
            background: "rgba(0, 0, 0, 0.05)",
            borderRadius: 10,
          }}
        >
          {currentPublishedTerms?.text}
        </div>
      </Modal>

      <Modal
        title="Aktuelle betingelser"
        open={showDraftTerms}
        onCancel={() => {
          setShowDraftTerms(false);
        }}
        footer={[
          <Button
            key="close"
            onClick={() => {
              setShowDraftTerms(false);
            }}
          >
            Luk
          </Button>,
          <Button
            key="saveDraft"
            type="primary"
            onClick={async () => {
              await saveDraft(draftedTerms);
            }}
          >
            Gem kladde
          </Button>,
          <Button
            key="saveAndPublish"
            danger
            onClick={() => {
              saveAndPublish(draftedTerms);
            }}
          >
            Gem og publicér
          </Button>,
        ]}
      >
        <Input.TextArea
          rows={10}
          value={draftedTerms?.text}
          defaultValue={currentDraftTerms?.text}
          onChange={(e) => {
            setDraftedTerms({
              ...currentDraftTerms,
              text: e.target.value,
            });
          }}
        />
      </Modal>

      {/* <Modal
        title="Annuller ændringer"
        open={closeDraftModal}
        onCancel={() => {
          setCloseDraftModal(false);
        }}
        onOk={() => {
          setCloseDraftModal(false);
          setShowDraftTerms(false);
        }}
        cancelText="Annuller"
        okText="Annuller ændringer"
      >
        <p>Er du sikker på at du vil annullere dine ændringer?</p>
      </Modal> */}
    </>
  );
};
