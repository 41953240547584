import { api } from "./API.service";
import { MealCategory } from "../Models/MealCategory";

class MealCategoryService {
  /**
   * Get meal categories
   * @returns Promise containing an array of meal categories
   */
  async getMealCategories(): Promise<MealCategory[]> {
    console.log("Get Meal Categories");
    try {
      const response = await api.makeRequest("/admin/meal-category");
      return response.content;
    } catch (error) {
      console.error("Failed to fetch meal categories:", error);
      throw error;
    }
  }

  /**
   * Create meal category
   * @param mealCategory The meal category to create
   * @returns Promise containing a boolean indicating the success of the creation
   */
  async createMealCategory(mealCategory: MealCategory): Promise<boolean> {
    try {
      await api.makeRequest("/admin/meal-category", "POST", mealCategory);
      return true;
    } catch (error) {
      console.error("Failed to create meal category:", error);
      return false;
    }
  }

  /**
   * Update meal category
   * @param mealCategory The updated meal category
   * @param mealCategories All existing meal categories
   * @returns Promise containing a boolean indicating the success of the update
   */
  async updateMealCategory(mealCategory: MealCategory[]): Promise<boolean> {
    try {
      await api.makeRequest(`/admin/meal-category`, "PATCH", mealCategory);
      return true;
    } catch (error) {
      console.error("Failed to update meal category:", error);
      throw error;
    }
  }

  /**
   * Delete meal category
   * @param mealCategoryId ID of the meal category to delete
   * @returns Promise containing a boolean indicating the success of the deletion
   */
  async deleteMealCategory(
    mealCategory: MealCategory,
    mealCategories: MealCategory[]
  ): Promise<boolean> {
    const orderToDelete = mealCategory.order;

    // Update the order of all meal categories with a higher order than the one to delete
    const mealCategoriesToUpdate = mealCategories.filter(
      (mealCategory) => mealCategory.order > orderToDelete
    );

    const updatedMealCategories = mealCategoriesToUpdate.map((mealCategory) => {
      return {
        ...mealCategory,
        order: mealCategory.order - 1,
      };
    });

    try {
      await api.makeRequest(
        `/admin/meal-category/${mealCategory.id}`,
        "DELETE"
      );
      await this.updateMealCategory(updatedMealCategories);
      return true;
    } catch (error) {
      console.error(
        `Failed to delete meal category with ID ${mealCategory.id}:`,
        error
      );
      return false;
    }
  }
}

export const mealCategoryService = new MealCategoryService();
