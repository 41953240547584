import axios, { AxiosRequestConfig } from "axios";
import { firebaseService } from "./Firebase.service";

const API_URL = process.env.REACT_APP_API_URL;
// const API_KEY = process.env.REACT_APP_API_KEY;

const axiosInstance = axios.create({
  baseURL: API_URL,
});

export const makeRequest = async (
  method: AxiosRequestConfig["method"],
  url: string,
  body?: Record<string, any> | FormData
) => {
  const user = await firebaseService.getCurrentUser();

  if (!user) {
    throw new Error("User not authenticated [AXS:#22]");
  }

  const token = await user.getIdToken();

  const config: AxiosRequestConfig = {
    method,
    url,
    headers: {
      "X-Api-Key": token,
      Authorization: `Bearer ${token}`,
    },
  };

  if (body) {
    if (body instanceof FormData) {
      config.headers = {
        "Content-Type": "multipart/form-data",
        ...config.headers,
      };
      config.data = body;
    } else {
      config.headers = {
        "Content-Type": "application/json",
        ...config.headers,
      };
      config.data = JSON.stringify(body);
    }
  }

  const response = await axiosInstance.request(config);

  return response.data;
};
