import { FC, useEffect, useState } from "react"
import { Recipe } from "../Models/Recipe"
import {
  Button,
  Col,
  Descriptions,
  Image,
  Input,
  Radio,
  Row,
  Space,
  Table,
} from "antd"
import { recipeService } from "../Services/Recipe.service"
import { CheckOutlined, CloseSquareOutlined } from "@ant-design/icons"
import { hostedImage } from "../Helpers/Image.helper"
import { useRecipeStore } from "../State/Recipe.store"
import { isMobile } from "../Helpers/Responsive.helper"

interface RecipesTableProps {
  onCreate: () => void
  onEdit: (recipe: Recipe) => void
  refetch: number
}
export const RecipesTable: FC<RecipesTableProps> = ({
  onCreate,
  onEdit,
  refetch,
}) => {
  // States
  const [isFetching, setIsFetching] = useState<boolean>(false)
  // const [recipes, setRecipes] = useState<Recipe[]>([]);
  const [filterRecipesQuery, setFilterRecipesQuery] = useState<string>("")
  const { recipes, setRecipes } = useRecipeStore()

  const premiumRecipes = recipes.filter((recipe) => recipe.premium)
  const freeRecipes = recipes.filter((recipe) => !recipe.premium)

  const [viewFilter, setViewFilter] = useState<string>("all")
  const [otherFilter, setOtherFilter] = useState<string>("all")

  // Filtered recipes
  const filteredRecipes = recipes.filter((recipe) => {
    const titleMatches = recipe.title
      .toLowerCase()
      .includes(filterRecipesQuery.toLowerCase())

    let viewFilterMatches = false
    let otherFilterMatches = false

    if (viewFilter === "all") {
      viewFilterMatches = true
    } else if (viewFilter === "premium") {
      viewFilterMatches = recipe.premium
    } else if (viewFilter === "free") {
      viewFilterMatches = !recipe.premium
    }

    if (otherFilter === "all") {
      otherFilterMatches = true
    } else if (otherFilter === "missing-primary-image") {
      otherFilterMatches =
        !recipe.primaryImageURL || recipe?.primaryImageURL?.length === 0
    } else if (otherFilter === "missing-gallery-image") {
      otherFilterMatches =
        !recipe?.galleryImageURL || recipe?.galleryImageURL?.length === 0
    } else if (otherFilter === "draft") {
      otherFilterMatches = recipe?.draft
    }

    return titleMatches && viewFilterMatches && otherFilterMatches
  })

  // Fetch the recipes every time the page is loaded
  useEffect(() => {
    fetchRecipes()
  }, [refetch])

  // Fetch the recipes from the recipeService
  async function fetchRecipes() {
    setIsFetching(true)

    try {
      const recipes: Recipe[] = await recipeService.getRecipes()
      setRecipes(recipes)
      // setRecipes(recipes);
      setIsFetching(false)
    } catch (error) {
      setIsFetching(false)
      console.error("Error fetching recipes")
    }
  }

  // Handle the filter query change
  function handleFilterQuerychange(event: React.ChangeEvent<HTMLInputElement>) {
    setFilterRecipesQuery(event.target.value)
  }

  // Recipe table columns
  const columns: any = []

  if (!isMobile) {
    columns.push({
      title: "Billede",
      dataIndex: "primaryImageURL",
      align: "center" as any,
      width: 80,
      key: "primaryImageURL",
      render: (primaryImageURL: string) =>
        primaryImageURL?.length > 0 ? (
          <Image src={primaryImageURL} />
        ) : (
          <CloseSquareOutlined />
        ),
    })

    columns.push({
      title: "Måltidsbillede",
      dataIndex: "galleryImageURL",
      key: "galleryImageURL",
      align: "center" as any,
      width: 80,
      render: (galleryImageURL: string) =>
        galleryImageURL?.length > 0 ? (
          <Image
            src={hostedImage(galleryImageURL, "gallery")}
            style={{ maxWidth: 48 }}
          />
        ) : (
          <CloseSquareOutlined />
        ),
    })
  }

  columns.push({
    title: "Titel",
    dataIndex: "title",
    key: "title",
  })

  columns.push({
    title: "Premium",
    dataIndex: "premium",
    key: "premium",
    align: "center" as any,
    width: 120,
    render: (premium: boolean) => (premium ? <CheckOutlined /> : ""),
  })

  columns.push({
    title: "Kladde",
    dataIndex: "draft",
    key: "draft",
    align: "center" as any,
    width: 80,
    render: (draft: boolean) => (draft ? <CheckOutlined /> : ""),
  })

  function resetFilters() {
    setViewFilter("all")
    setOtherFilter("all")
  }

  return (
    <>
      <Row style={{ marginBottom: 24 }}>
        <Col span={16}>
          <Descriptions title="Opskrifter" layout="vertical">
            <Descriptions.Item label="Opskrifter i alt">
              {recipes?.length}
            </Descriptions.Item>
            <Descriptions.Item label="Gratis opskrifter">
              {freeRecipes?.length}
            </Descriptions.Item>
            <Descriptions.Item label="Premium opskrifter">
              {premiumRecipes?.length}
            </Descriptions.Item>
          </Descriptions>
        </Col>

        <Col
          span={8}
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.05)",
            padding: 14,
            borderRadius: 4,
          }}
        >
          <Space direction="vertical">
            <Col span={24}>
              <h4>Visningsmuligheder</h4>
            </Col>
            <Radio.Group
              value={viewFilter}
              onChange={(e) => setViewFilter(e.target.value)}
            >
              <Radio.Button value="all">Alle</Radio.Button>
              <Radio.Button value="free">Gratis</Radio.Button>
              <Radio.Button value="premium">Premium</Radio.Button>
            </Radio.Group>

            <Col span={24} style={{ marginTop: 16 }}>
              <h4>Øvrige filtre</h4>
            </Col>

            <Radio.Group
              value={otherFilter}
              onChange={(e) => setOtherFilter(e.target.value)}
              style={{ marginBottom: 16 }}
            >
              <Space direction="vertical">
                <Radio value="all">Alle</Radio>
                <Radio value="missing-primary-image">
                  Mangler opskriftsbillede
                </Radio>
                <Radio value="missing-gallery-image">
                  Mangler inspirationsbillede
                </Radio>
                <Radio value="draft">Kladde</Radio>
              </Space>
            </Radio.Group>

            <Button onClick={resetFilters}>Nulstil filtre</Button>
          </Space>
        </Col>
      </Row>

      <Row>
        <Col xs={24} md={18}>
          <Input
            placeholder="Find opskrift"
            value={filterRecipesQuery}
            onChange={handleFilterQuerychange}
          />
        </Col>

        {isMobile && (
          <Col xs={{ span: 24 }} md={{ span: 1, offset: 0 }}>
            <br />
          </Col>
        )}

        <Col xs={{ span: 24 }} md={{ offset: 1, span: 5 }}>
          <Button type="primary" onClick={onCreate} style={{ width: "100%" }}>
            Opret Opskrift
          </Button>
        </Col>
      </Row>
      <Row style={{ marginTop: "16px" }}>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={filteredRecipes}
            loading={isFetching}
            rowKey="id"
            scroll={{ x: "max-content", y: 500 }}
            onRow={(record, rowIndex) => ({
              onClick: () => {
                onEdit(record)
              },
            })}
          />
        </Col>
      </Row>
    </>
  )
}
