import { api } from "./API.service";
import { IngredientUnit } from "../Models/IngredientUnit";

class IngredientUnitService {
  /**
   * Get meal categories
   * @returns Promise containing an array of meal categories
   */
  async getIngredientUnits(): Promise<IngredientUnit[]> {
    try {
      const response = await api.makeRequest("/admin/ingredient-unit");
      return response.content;
    } catch (error) {
      console.error("Failed to fetch meal categories:", error);
      throw error;
    }
  }

  /**
   * Create meal category
   * @param ingredientUnit The meal category to create
   * @returns Promise containing a boolean indicating the success of the creation
   */
  async createIngredientUnit(ingredientUnit: IngredientUnit): Promise<boolean> {
    try {
      await api.makeRequest("/admin/ingredient-unit", "POST", ingredientUnit);
      return true;
    } catch (error) {
      console.error("Failed to create meal category:", error);
      return false;
    }
  }

  /**
   * Update meal category
   * @param ingredientUnit The updated meal category
   * @param ingredientUnits All existing meal categories
   * @returns Promise containing a boolean indicating the success of the update
   */
  async updateIngredientUnit(
    ingredientUnit: IngredientUnit[]
  ): Promise<boolean> {
    try {
      await api.makeRequest(`/admin/ingredient-unit`, "PATCH", ingredientUnit);
      return true;
    } catch (error) {
      console.error("Failed to update meal category:", error);
      throw error;
    }
  }

  /**
   * Delete meal category
   * @param ingredientUnitId ID of the meal category to delete
   * @returns Promise containing a boolean indicating the success of the deletion
   */
  async deleteIngredientUnit(
    ingredientUnit: IngredientUnit,
    ingredientUnits: IngredientUnit[]
  ): Promise<boolean> {
    try {
        await api.makeRequest(
            `/admin/ingredient-unit/${ingredientUnit.id}`,
            "DELETE"
        );
        return true;
    } catch (error) {
        console.error(
            `Failed to delete unit with ID ${ingredientUnit.id}:`,
            error
        );
        return false;
    }
  }
}

export const ingredientUnitService = new IngredientUnitService();
