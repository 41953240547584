import { firebaseService } from "./Firebase.service";

class APIService {
  API_URL: string;
  API_KEY: string;

  /**
   * Creates an instance of APIService.
   */
  constructor() {
    this.API_URL = process.env.REACT_APP_API_URL ?? "";
    this.API_KEY = process.env.REACT_APP_API_KEY ?? "";
  }

  async calculateBody(body: any = null, file: any = null) {
    if (file) {
      const formData = new FormData()
      formData.append('file', file)

      return formData
    }

    if (body) {
      return JSON.stringify(body)
    }

    return null
  }

  /**
   *
   * @param url
   * @param method
   * @param body
   * @returns
   */
  async makeRequest(
    url: string,
    method = "GET",
    body: any = null,
    file: any = null
  ) {
    try {
      const requestPayload: any =  await this.calculateBody(body, file)
      const user = await firebaseService.getCurrentUser();
    
      if (!user) {
        throw new Error("User not authenticated [AXS:#22]");
      }
    
      const token = await user.getIdToken();

      const headers = {
        "Content-Type": file ? "multipart/form-data" : "application/json",
        Accept: file ? "*/*" : "application/json",
        "x-api-key": this.API_KEY,
        Authorization: `Bearer ${token}`,
      }

      if (file) {
        headers["Content-Type"] = "multipart/form-data; boundary=" + requestPayload._boundary;
      } else {
        headers["Content-Type"] = "application/json";
      }
      
      const response = await fetch(`${this.API_URL}${url}`, {
        method,
        headers,
        body: requestPayload
      });

      if (method === "DELETE") {
        return true;
      }

      if (!response?.ok) {
        throw new Error("Request failed");
      }

      // if (method !== "GET") {
      //   return true
      // }

      if (method === "PUT" || method === "PATCH") {
        return true;
      }

      const data = await response.json();

      if (typeof data === "boolean") {
        return data;
      }

      if (data) {
        return data;
      }

      return true;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

export const api = new APIService();
