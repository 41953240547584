import { message } from "antd";
import { api } from "./API.service";

class TermsService {
  async getCurrentPublishedTerms() {
    try {
      const response = await api.makeRequest("/admin/terms/current");
      return response.content;
    } catch (error) {
      console.error("Failed to fetch current published terms:", error);
      throw error;
    }
  }

  async createNewTerms() {
    message.loading({ content: "Opretter ny kladde", key: "createTerms" });

    try {
      const response = await api.makeRequest("/admin/terms", "POST", {
        text: "",
        draft: true,
      });

      message.success({
        content: "Ny kladde oprettet",
        key: "createTerms",
      });

      return response.content;
    } catch (error) {
      console.error("Failed to create terms:", error);
      message.error({
        content: "Der skete en fejl under oprettelse af kladde",
        key: "createTerms",
      });

      throw error;
    }
  }

  async getCurrentDraftTerms() {
    const draftExists = await this.draftExists();

    if (!draftExists) {
      const newTerms = this.createNewTerms();

      return newTerms;
    }

    try {
      const response = await api.makeRequest(
        "/admin/terms/current?drafts=true"
      );
      return response.content;
    } catch (error) {
      console.error("Failed to fetch current draft terms:", error);
      throw error;
    }
  }

  // /terms/draft-exists

  async draftExists() {
    try {
      const response = await api.makeRequest("/admin/terms/draft-exists");
      return response;
    } catch (error) {
      console.error("Failed to fetch current draft terms:", error);
      throw error;
    }
  }

  async updateDraftTerms(terms: any) {
    message.loading({ content: "Opdaterer vilkår kladde", key: "updateTerms" });

    try {
      const response = await api.makeRequest(
        "/admin/terms/" + terms.id,
        "PUT",
        {
          text: terms.text,
          draft: true,
        }
      );

      message.success({
        content: "Vilkår kladde opdateret",
        key: "updateTerms",
      });

      return response.content;
    } catch (error) {
      console.error("Failed to update draft terms:", error);

      message.error({
        content: "Der skete en fejl under opdatering af vilkår kladde",
        key: "updateTerms",
      });

      throw error;
    }
  }

  async publishDraftTerms(terms: any) {
    message.loading({ content: "Publicerer vilkår", key: "publishTerms" });

    try {
      const response = await api.makeRequest(
        "/admin/terms/" + terms.id,
        "PUT",
        {
          text: terms.text,
          draft: false,
        }
      );

      message.success({
        content: "Vilkår publiceret",
        key: "publishTerms",
      });

      return response;
    } catch (error) {
      console.error("Failed to publish draft terms:", error);

      message.error({
        content: "Der skete en fejl under publicering af vilkår",
        key: "publishTerms",
      });

      throw error;
    }
  }
}
export const termsService = new TermsService();
