export function bytesToKilobytes(bytes: number): number {
  const raw = bytes / 1024;
  const rounded = Math.round(raw * 100) / 100;
  return rounded;
}

export function bytesToMegabytes(bytes: number): number {
  const raw = bytes / (1024 * 1024);
  const rounded = Math.round(raw * 100) / 100;

  return rounded;
}
