import { useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Table,
  Input,
  Checkbox,
  notification,
  message,
} from "antd";

import { IngredientUnit } from "../Models/IngredientUnit";

import { ingredientUnitService } from "../Services/IngredientUnit.service";
import { CheckOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";

export function IngredientUnitsPage() {
  const [updateFormInstance] = useForm();

  const createForm: any = useRef({});
  const updateForm: any = useRef({});

  const [dataSource, setDataSource] = useState<IngredientUnit[]>([]);

  const [isShowingCreateModal, setIsShowingCreateModal] = useState(false);
  const [isShowingUpdateModal, setIsShowingUpdateModal] = useState<
    number | null
  >(null);
  const [isFetching, setIsFetching] = useState(false);
  const [filterQuery, setFilterQuery] = useState("");

  const filteredDatasource = useMemo(() => {
    return dataSource.filter((ingredientUnit) =>
      ingredientUnit.name.toLowerCase().includes(filterQuery.toLowerCase())
    );
  }, [dataSource, filterQuery]);

  const activeUpdateModel = useMemo(
    () =>
      dataSource.find(
        (ingredientUnit) => ingredientUnit.id === isShowingUpdateModal
      ),
    [dataSource, isShowingUpdateModal]
  );

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    setIsFetching(true);

    try {
      const ingredientUnits: IngredientUnit[] =
        await ingredientUnitService.getIngredientUnits();
      setDataSource(ingredientUnits);
      setIsFetching(false);
    } catch (error) {
      setIsFetching(false);
    }
  }

  const columns = [
    {
      title: "Navn",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Forkortelse",
      dataIndex: "shortName",
      key: "shortName",
      align: "center" as any,
      width: 40,
    },
  ];

  /**
   * Update modal
   */

  function openUpdateModal(id: number) {
    setIsShowingUpdateModal(id);

    const activeIngredient = dataSource.find(
      (ingredient) => ingredient.id === id
    );

    updateFormInstance.setFieldsValue(activeIngredient);
  }

  function closeUpdateModal() {
    setIsShowingUpdateModal(null);
    updateFormInstance.resetFields();
  }

  /**
   * Create modal
   */
  function openCreateModal() {
    setIsShowingCreateModal(true);
  }

  function closeCreateModal() {
    setIsShowingCreateModal(false);
    createForm.current.resetFields();
  }

  function handleCreateAction() {
    const form = createForm.current;
    form.submit();
  }

  async function onUpdateSubmit(values: any) {
    try {
      const success = await ingredientUnitService.updateIngredientUnit([
        values,
      ]);

      if (!!success) {
        await fetchData();
        closeUpdateModal();

        message.success(`"${values.name}" er blevet opdateret`);
      }
    } catch (error) {
      throw error;
    }
  }

  function onUpdateSubmitFailed(errorInfo: any) {
    console.error("Failed:", errorInfo);
  }

  async function onCreateSubmit(values: any) {
    values.order = dataSource.length;
    const success = await ingredientUnitService.createIngredientUnit(values);

    if (!!success) {
      message.success(`"${values.name}" er blevet oprettet`);

      await fetchData();
      closeCreateModal();
    }
  }

  function onCreateSubmitFailed(errorInfo: any) {
    console.error("Failed:", errorInfo);
  }

  async function onDelete() {
    if (!activeUpdateModel?.id) return;

    const success = await ingredientUnitService.deleteIngredientUnit(
      activeUpdateModel,
      dataSource
    );

    if (!!success) {
      closeUpdateModal();
      fetchData();

      message.success(`"${activeUpdateModel?.name}" er blevet slettet`);
    }
  }

  const handleFilterQuerychange = (event: any) => {
    setFilterQuery(event.target.value);
  };

  return (
    <>
      <Row>
        <Col flex={1}>
          <Input
            placeholder="Find enhed"
            value={filterQuery}
            onChange={handleFilterQuerychange}
          />
        </Col>
        <Col offset={1}>
          <Button type="primary" onClick={openCreateModal}>
            Opret Enhed
          </Button>
        </Col>
      </Row>

      <Row style={{ marginTop: "16px" }}>
        <Col span={8}>
          <Table
            bordered={true}
            dataSource={filteredDatasource}
            columns={columns}
            loading={isFetching}
            rowKey={(record) => record.id.toString()}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  openUpdateModal(record.id);
                },
              };
            }}
          />
        </Col>
      </Row>

      {/* Update modal */}

      <Modal
        title={`Rediger "${activeUpdateModel?.name}"`}
        open={!!isShowingUpdateModal}
        okText="Opdater"
        cancelText="Annuller"
        onCancel={closeUpdateModal}
        footer={[
          <Row key="meal-categories-update-footer">
            <Button
              key="delete"
              danger
              onClick={() => {
                Modal.confirm({
                  title: "Er du sikker?",
                  content: `Er du sikker på, at du vil slette "${activeUpdateModel?.name}"?`,
                  okText: "Ja, jeg er sikker",
                  cancelText: "Annuller",
                  onOk: () => {
                    onDelete();
                  },
                });
              }}
            >
              Slet
            </Button>
            <span key="spacer" style={{ flex: 1 }} />
            <Button
              key="cancel"
              onClick={() => {
                closeUpdateModal();
              }}
            >
              Annuller
            </Button>
            <Button
              key="update"
              type="primary"
              onClick={() => {
                onUpdateSubmit(updateFormInstance.getFieldsValue());
              }}
            >
              Opdater
            </Button>
          </Row>,
        ]}
      >
        <Form
          form={updateFormInstance}
          ref={updateForm}
          name="update-data"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          onFinish={onUpdateSubmit}
          onFinishFailed={onUpdateSubmitFailed}
          layout="vertical"
          autoComplete="off"
          initialValues={activeUpdateModel}
        >
          <Form.Item hidden={true} name="id">
            <Input disabled={true} />
          </Form.Item>

          {/* Name */}
          <Form.Item
            label="Navn"
            name="name"
            rules={[{ required: true, message: "Feltet skal udfyldes" }]}
          >
            <Input />
          </Form.Item>

          {/* Shortname */}
          <Form.Item
            label="Forkortelse"
            name="shortName"
            rules={[{ required: true, message: "Feltet skal udfyldes" }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>

      {/* Create modal */}
      <Modal
        title="Opret enhed"
        open={isShowingCreateModal}
        okText="Opret"
        cancelText="Annuller"
        onOk={handleCreateAction}
        // confirmLoading={confirmLoading}
        onCancel={closeCreateModal}
      >
        <Form
          ref={createForm}
          name="create-data"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          onFinish={onCreateSubmit}
          onFinishFailed={onCreateSubmitFailed}
          layout="vertical"
          autoComplete="off"
        >
          {/* Name */}
          <Form.Item
            label="Navn"
            name="name"
            rules={[{ required: true, message: "Feltet skal udfyldes" }]}
          >
            <Input />
          </Form.Item>

          {/* Shortname */}
          <Form.Item
            label="Forkortelse"
            name="shortName"
            rules={[{ required: true, message: "Feltet skal udfyldes" }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
