import React, { useEffect, useState } from "react"
import ReactDOM from "react-dom/client"
import "antd/dist/reset.css"
import {
  RouterProvider,
  createBrowserRouter,
  Routes,
  Route,
} from "react-router-dom"
import { NavigationBar } from "./Modules/NavigationBar"
import { Layout, theme } from "antd"
import { HomePage } from "./Pages/Home"
import { RecipesPage } from "./Pages/Recipes"
import { IngredientsPage } from "./Pages/Ingredients"
import { RecipeCategoriesPage } from "./Pages/RecipeCategories"
import { MealCategoriesPage } from "./Pages/MealCategories"
import { LoginPage } from "./Pages/Login"
import { onAuthStateChanged } from "firebase/auth"
import { firebaseService } from "./Services/Firebase.service"
import { LoadingOutlined } from "@ant-design/icons"
import { InspirationPage } from "./Pages/Inspiration"
import { IngredientUnitsPage } from "./Pages/IngredientUnits"
import { isMobile } from "./Helpers/Responsive.helper"
import { ArticlesPage } from "./Pages/Articles"
import { decodeJWT } from "./Helpers/JWT.helper"
import { LinksPage } from "./Pages/LinksPage"
import { TermsPage } from "./Pages/TermsPage"

const { Content } = Layout

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

const router = createBrowserRouter([
  {
    path: "*",
    element: <View />,
  },
])

function View() {
  const [isLoggedIn, setIsLoggedIn] = useState<any>(null)
  const [isDeveloper, setIsDeveloper] = useState<boolean>(false)

  const {
    token: { colorBgContainer },
  } = theme.useToken()

  useEffect(() => {
    onAuthStateChanged(firebaseService.auth, async (user) => {

      if (user) {
        const claims: string[] = (await user.getIdTokenResult()).claims.custom_claims;

        if(claims.includes("ADMIN") || claims.includes("DEV")) {
          setIsLoggedIn(true)
          setIsDeveloper(claims.includes("DEV"))
        } else {
          setIsLoggedIn(false)
          firebaseService.auth.signOut();
        }
        
      } else {
        setIsLoggedIn(false)
      }
    })
  }, [])

  if (isLoggedIn === null) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <LoadingOutlined style={{ fontSize: 100 }} />
      </div>
    )
  }

  const isDevelopment = process.env.NODE_ENV === "development"

  return (
    <React.StrictMode>
      <Layout style={{ minHeight: "100vh" }}>
        {isLoggedIn ? (
          <>
            <NavigationBar isDeveloper={isDeveloper} />

            <Content
              className="site-layout"
              style={{ padding: isMobile ? "20px" : "50px" }}
            >
              <div
                style={{
                  padding: 24,
                  minHeight: 380,
                  background: colorBgContainer,
                }}
              >
                <Routes>
                  <Route path="/" element={<HomePage />} index={true} />
                  <Route path="/inspiration" element={<InspirationPage />} />
                  <Route path="/recipes" element={<RecipesPage />} />
                  <Route path="/links" element={<LinksPage />} />
                  <Route path="/terms" element={<TermsPage />} />
                  <Route path="/ingredients" element={<IngredientsPage />} />
                  <Route
                    path="/ingredient-units"
                    element={<IngredientUnitsPage />}
                  />
                  <Route
                    path="/recipe-categories"
                    element={<RecipeCategoriesPage />}
                  />
                  <Route
                    path="/meal-categories"
                    element={<MealCategoriesPage />}
                  />
                  <Route path="/articles" element={<ArticlesPage />} />
                </Routes>
              </div>
            </Content>
          </>
        ) : (
          <LoginPage />
        )}
      </Layout>

      {isDevelopment && (
        <div
          style={{
            background: "#FF4015",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "fixed",
            bottom: 0,
            left: 0,
            zIndex: 9999,
            height: 50,
          }}
        >
          <b
            style={{
              border: "2px solid white",
              color: "white",
              padding: 6,
              fontSize: 14,
            }}
          >
            DEVELOPMENT MODE
          </b>
        </div>
      )}
    </React.StrictMode>
  )
}

root.render(<RouterProvider router={router} />)
