import { useState } from "react"
import { RecipesTable } from "../Modules/RecipesTable"
import { Row, Col } from "antd"
import { RecipeModal } from "../Modules/RecipeModal"
import { Recipe } from "../Models/Recipe"
import { recipeService } from "../Services/Recipe.service"

export function RecipesPage() {
  // States
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false)
  const [editRecipe, setEditRecipe] = useState<Recipe | undefined>(undefined)
  const [refetchCount, setRefetchCount] = useState<number>(0)

  // Toggle the create recipe modal
  async function toggleModal(recipe?: Recipe) {
    setEditRecipe(undefined);
    
    if (isValidRecipe(recipe)) {
      const rec = await recipeService.getRecipe(recipe.id.toString());
      setEditRecipe(rec);
    }

    setShowCreateModal(!showCreateModal);
    setRefetchCount(refetchCount + 1);
  }

  // Handle the create recipe event
  async function onCreateRecipe(updatedRecipe: Recipe, options: any) {
    try {
      const success = await recipeService.createRecipe(updatedRecipe, options)

      if (success) {
        toggleModal()
        setRefetchCount(refetchCount + 1)

        setEditRecipe(undefined)

        return
      }
    } catch (error) {}
  }

  // Verify that the recipe is valid
  function isValidRecipe(recipe?: Recipe): recipe is Recipe {
    return recipe?.id !== undefined
  }

  return (
    <>
      <Row>
        <Col span={24}>
          <RecipesTable
            refetch={refetchCount}
            onCreate={toggleModal}
            onEdit={toggleModal}
          />
        </Col>
      </Row>

      {/* Modals */}
      <RecipeModal
        key={editRecipe?.id}
        recipe={editRecipe}
        visible={showCreateModal}
        onCreate={onCreateRecipe}
        onCancel={toggleModal}
      />
    </>
  )
}
