import { Ingredient, IngredientCreation } from "../Models/Ingredient"
import { api } from "./API.service"

class IngredientService {
  /**
   * Get ingredients
   * @returns
   */
  async getIngredients(): Promise<Ingredient[]> {
    try {
      const response = await api.makeRequest(
        "/admin/ingredient?includeHidden=true"
      )
      return response.content
    } catch (error) {
      console.error("Noget gik galt under hentning af ingredienser [#IS:16] ")
      throw error
    }
  }

  /**
   * Create ingredient
   * @param ingredient Create ingredient
   */
  async createIngredient(ingredient: IngredientCreation): Promise<number> {
    try {
      const result = await api.makeRequest(
        "/admin/ingredient",
        "POST",
        ingredient
      )

      return result
    } catch (error) {
      console.error(
        "Der opstod en fejl under oprettelse af ingrediens [#IS:34]"
      )

      throw new Error("Unhandled exception: IngredientService#createIngredient")
    }
  }

  /**
   * Get single ingredient
   * @param ingredient ID
   */
  async getIngredient(id: number): Promise<Ingredient> {
    try {
      const result = await api.makeRequest(`/admin/ingredient/${id}`, "GET")
      return result.content
    } catch (error) {
      console.error("Der opstod en fejl under hentning af ingrediens [#IS:54]")
      throw new Error("Unhandled exception: IngredientService#getIngredient")
    }
  }

  async updateIngredient(ingredient: Ingredient): Promise<boolean> {
    try {
      await api.makeRequest(`/admin/ingredient`, "PATCH", [ingredient])

      return true
    } catch (error) {
      console.error(
        "Der opstod en fejl under opdatering af ingrediens [#IS:67]"
      )

      throw new Error(
        "Unhandled exception: IngredientService#updateIngredient [#IS:70]"
      )
    }
  }

  /**
   * Delete ingredient
   * @param ingredientId ID of the ingredient to delete
   * @returns true if the deletion was successful, false otherwise
   */
  async deleteIngredient(ingredientId: number): Promise<boolean> {
    try {
      await api.makeRequest(`/admin/ingredient/${ingredientId}`, "DELETE")

      return true
    } catch (error) {
      console.error(
        `Failed to delete ingredient with ID ${ingredientId}:`,
        error
      )

      return false
    }
  }
}

export const ingredientService = new IngredientService()
