import {
  Modal,
  Row,
  Col,
  Input,
  Select,
  Button,
  Checkbox,
  Form,
  Typography,
  Upload,
} from "antd"
import { useForm } from "antd/es/form/Form"
import { useEffect, useRef, useState } from "react"
import { ArticleCategory, articleService } from "../Services/Article.service"
import { UploadOutlined } from "@ant-design/icons"

export const ArticleCreateModal = ({ isShowing, onClose }: any) => {
  const articleFormRef: any = useRef({})
  const [articleForm] = useForm()
  const { Text } = Typography

  const [articleCategories, setArticleCategories] = useState<ArticleCategory[]>(
    []
  )
  const [articleModalLinks, setArticleModalLinks] = useState<any[]>([])
  const [isPremium, setIsPremium] = useState<boolean>(false)
  const [isDraft, setIsDraft] = useState<boolean>(true)
  const [imageChanged, setImageChanged] = useState<boolean>(false)

  /**
   * Fetch article categories on mount
   */
  useEffect(() => {
    fetchArticleCategories()
  }, [])

  /**
   * Fetch article categories
   * @returns {Promise<void>}
   */
  async function fetchArticleCategories() {
    try {
      const articleCategories: ArticleCategory[] =
        await articleService.getArticleCategories()

      setArticleCategories(articleCategories)
    } catch (error) {}
  }

  async function saveArticle(values: any) {
    const article = {
      ...values,
      premium: isPremium,
      draft: isDraft,
      articleLinks: articleModalLinks,
    }

    const success = await articleService.createArticle(article, imageChanged)
    if (success) {
      onClose()
    }
  }

  function addArticleModalLink() {
    const links = [...articleModalLinks]
    links.push({ url: "", label: "", order: articleModalLinks.length + 1 })
    setArticleModalLinks(links)
  }

  function setArticleModalLink(key: string, value: string, index: number) {
    const links = [...articleModalLinks]
    links[index][key] = value
    setArticleModalLinks(links)
  }

  function removeArticleLink(index: number) {
    const links = [...articleModalLinks]
    links.splice(index, 1)
    setArticleModalLinks(links)
  }

  function handleCheckboxTick(key: string, value: boolean) {
    if (key === "draft") {
      setIsDraft(value)
    } else if (key === "premium") {
      setIsPremium(value)
    }
  }

  async function handleBeforeUpload(key: string, event?: any) {
    setImageChanged(true)
    return false
  }

  return (
    <Modal
      title="Opret artikel"
      open={isShowing}
      onCancel={onClose}
      onOk={() => articleFormRef.current.submit()}
      width={700}
    >
      <Form
        layout="vertical"
        onFinish={saveArticle}
        autoComplete="off"
        form={articleForm}
        ref={articleFormRef}
        initialValues={{
          isPremium: false,
          isDraft: true,
          articleLinks: [],
          articleCategories: [articleCategories[0]?.id],
        }}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="title"
              label="Titel"
              rules={[{ required: true, message: "Feltet skal udfyldes" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="articleCategories" label="Kategorier">
              <Select mode="multiple">
                {articleCategories.map((category) => (
                  <Select.Option value={category.id}>
                    {category.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginBottom: 16 }}>
          <Col span={24}>
            <Form.Item
              name="content"
              label="Indhold"
              rules={[{ required: true, message: "Feltet skal udfyldes" }]}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16} style={{ marginBottom: 16 }}>
          <Col span={24}>
            <Button onClick={addArticleModalLink}>Tilføj link</Button>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            {articleModalLinks?.map((link: any, index: number) => (
              <Row gutter={16} style={{ marginBottom: 16 }} key={index}>
                <Col span={7}>
                  <Input
                    placeholder="Tekst"
                    value={link.label}
                    onChange={(e) =>
                      setArticleModalLink("label", e.target.value, index)
                    }
                  />
                </Col>
                <Col style={{ flexGrow: 1 }}>
                  <Input
                    placeholder="Link"
                    value={link.url}
                    onChange={(e) =>
                      setArticleModalLink("url", e.target.value, index)
                    }
                  />
                </Col>
                <Col>
                  <Button danger onClick={() => removeArticleLink(index)}>
                    Fjern link
                  </Button>
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
        <Row>
          <Form.Item label="Billede" name="headerImageURL">
            <Upload
              listType="picture"
              maxCount={1}
              beforeUpload={() => handleBeforeUpload("primaryImageURL")}
              onChange={(e) => handleBeforeUpload("primaryImageURL", e)}
              defaultFileList={[]}
            >
              <Button icon={<UploadOutlined />}>Upload (Max: 1)</Button>
            </Upload>
          </Form.Item>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <>
              <Checkbox
                checked={isPremium}
                onChange={(e) =>
                  handleCheckboxTick("premium", e.target.checked)
                }
              />
              <Text style={{ marginLeft: 8 }}>Kun for premium brugere</Text>
            </>
          </Col>
          <Col span={12}>
            <Checkbox
              checked={isDraft}
              onChange={(e) => handleCheckboxTick("draft", e.target.checked)}
            />
            <Text style={{ marginLeft: 8 }}>Kladde?</Text>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
