import { useEffect, useState } from "react";
import { linksService } from "../Services/Links.service";
import { Button, Col, Form, Input, Modal, Row, Space, Table } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";

export const LinksPage = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [dataSource, setDataSource] = useState<any[]>([]);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [activeLink, setActiveLink] = useState<any>(null);
  const [form] = useForm();

  /**
   * Effects
   */
  useEffect(() => {
    fetchLinks();
  }, []);

  /**
   * Fetch links
   */
  async function fetchLinks() {
    setIsFetching(true);

    try {
      const links: any[] = await linksService.getLinks();
      setDataSource(links);
      setIsFetching(false);
    } catch (error) {
      setIsFetching(false);
    }
  }

  const columns = [
    {
      title: "Tekst",
      dataIndex: "label",
      key: "label",
    },
    {
      title: "Link",
      dataIndex: "link",
      key: "link",
      align: "center" as any,
      width: 80,
      render: (link: string) => (link?.length > 0 ? <CheckOutlined /> : ""),
    },
    {
      title: "Text",
      dataIndex: "text",
      key: "text",
      align: "center" as any,
      width: 80,
      render: (text: string) => (text?.length > 0 ? <CheckOutlined /> : ""),
    },
  ];

  function handleFinish() {
    if (activeLink) {
      // Update
      linksService.updateLink(form.getFieldsValue()).then(() => {
        fetchLinks();
        form.resetFields();
        setShowModal(false);
        setActiveLink(null);
      });
    } else {
      // Create
      linksService.createLink(form.getFieldsValue()).then(() => {
        fetchLinks();
        form.resetFields();
        setShowModal(false);
        setActiveLink(null);
      });
    }
  }

  return (
    <>
      <div>
        <Row style={{ marginBottom: 16 }}>
          <Col span={24}>
            <Button type="primary" onClick={() => setShowModal(true)}>
              Opret link
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              dataSource={dataSource}
              loading={isFetching}
              columns={columns}
              rowKey="id"
              onRow={(record) => {
                return {
                  onClick: () => {
                    setActiveLink(record);
                    setShowModal(true);
                    form.setFieldsValue(record);
                  },
                };
              }}
            />
          </Col>
        </Row>
      </div>

      <Modal
        title={activeLink ? "Rediger link" : "Opret link"}
        open={showModal}
        onOk={() => {
          form.submit();
        }}
        onCancel={() => {
          form.resetFields();
          setActiveLink(null);
          setShowModal(false);
        }}
        okText="Gem"
        cancelText="Annuller"
        width={400}
      >
        <Form
          layout="vertical"
          form={form}
          autoComplete="off"
          onFinish={handleFinish}
        >
          <Form.Item name="id" hidden={true}>
            <Input />
          </Form.Item>

          <Row>
            <Col span={24}>
              <Form.Item name="label" label="Tekst" required>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item name="link" label="Link">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item name="text" label="Text">
                <Input.TextArea />
              </Form.Item>
            </Col>
          </Row>
          {activeLink && (
            <Row>
              <Col span={24}>
                <Space>
                  <Button
                    type="primary"
                    danger
                    onClick={() => {
                      linksService.deleteLink(activeLink.id).then(() => {
                        fetchLinks();
                        form.resetFields();
                        setShowModal(false);
                        setActiveLink(null);
                      });
                    }}
                  >
                    Slet
                  </Button>
                </Space>
              </Col>
            </Row>
          )}
        </Form>
      </Modal>
    </>
  );
};
