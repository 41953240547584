import { CloseSquareOutlined } from "@ant-design/icons";
import { Button, Col, Image, Row, Table } from "antd";
import { useEffect, useState } from "react";
import { inspirationService } from "../Services/Inspiration.service";
import { InspirationModal } from "../Modules/InspirationModal";

export function InspirationPage() {
  const [showModal, setShowModal] = useState(false);
  const [inspirationItems, setInspirationItems] = useState<any[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [inspiration, setInspiration] = useState<any>({});

  async function toggleModal(inspiration?: any) {
    setInspiration(inspiration);

    if (showModal) {
      window.location.reload();
    }

    setShowModal(!showModal);
  }

  async function fetchInspirationItems() {
    setIsFetching(true);

    try {
      const inspirationItems: any[] = await inspirationService.getInspiration();

      setInspirationItems(inspirationItems);
      setIsFetching(false);
    } catch (error) {
      setIsFetching(false);
    }
  }

  useEffect(() => {
    fetchInspirationItems();
  }, [inspiration]);

  const columns = [
    {
      title: "Billede",
      dataIndex: "imageUrl",
      align: "center" as any,
      width: 80,
      key: "imageUrl",
      render: (imageUrl: string) =>
        imageUrl?.length > 0 ? (
          <Image src={imageUrl} />
        ) : (
          <CloseSquareOutlined />
        ),
    },
    {
      title: "Titel",
      dataIndex: "title",
      key: "title",
    },
  ];

  return (
    <>
      <Button onClick={toggleModal}>Opret måltidsinspiration</Button>

      <Row style={{ marginTop: "16px" }}>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={inspirationItems}
            loading={isFetching}
            rowKey="id"
            scroll={{ x: "max-content", y: 500 }}
            onRow={(record, rowIndex) => ({
              onClick: () => {
                toggleModal(record);
              },
            })}
          />
        </Col>
      </Row>

      <InspirationModal
        isShowing={showModal}
        onClose={toggleModal}
        inspiration={inspiration}
      />
    </>
  );
}
