import { message } from "antd"
import { api } from "./API.service"
import { imageService } from "./Image.service"

class InspirationService {
  async getInspiration() {
    try {
      const response = await api.makeRequest("/admin/gallery")
      return response.content
    } catch (error) {
      console.error("Failed to fetch inspiration:", error)
      throw error
    }
  }

  async uploadInspirationImage(file: any) {
    try {
      message.open({
        key: "createInspiration",
        type: "loading",
        content: "Uploader inspirationsbillede...",
        duration: 0,
      })

      const mediaSlug = await imageService.convertAndUploadImage(
        file.originFileObj,
        "gallery"
      )

      message.open({
        key: "createInspiration",
        type: "success",
        content: "Inspirationsbillede uploadet",
      })

      return mediaSlug
    } catch (error) {
      throw error
    }
  }

  async createInspiration(inspiration: any, imageChanged: boolean) {
    let headerFileName: string = ""

    let errorCode = 0
    console.log(0, inspiration)

    try {
      message.open({
        key: "createInspiration",
        type: "loading",
        content: "Opretter inspiration...",
        duration: 0,
      })

      if (inspiration.imageUrl?.fileList?.length > 0) {
        const mediaSlug = await this.uploadInspirationImage(
          inspiration.imageUrl.fileList[0]
        )

        errorCode = 1

        console.log(1, mediaSlug)

        if (mediaSlug) {
          headerFileName = mediaSlug
        }

        message.open({
          key: "createInspiration",
          type: "success",
          content: "Inspirationsbillede uploadet",
        })

        inspiration.imageUrl = imageChanged
          ? headerFileName
          : inspiration.imageUrl ?? ""

        errorCode = 2

        await api.makeRequest("/admin/gallery", "POST", inspiration)

        message.success({
          content: "Inspiration oprettet",
          key: "createInspiration",
        })

        return true
      }

      return false
    } catch (error) {
      console.error("Failed to create inspiration:", error)

      message.error({
        content: "Der skete en fejl under oprettelse af inspiration",
        key: "createInspiration",
      })

      return false
    }
  }

  async deleteInspiration(inspirationID: any) {
    try {
      await api.makeRequest(`/admin/gallery/${inspirationID}`, "DELETE")
      return true
    } catch (error) {
      console.error("Failed to delete inspiration:", error)
      return false
    }
  }

  async updateInspiration(inspiration: any) {
    try {
      message.open({
        key: "updateInspiration",
        type: "loading",
        content: "Opdaterer inspiration...",
        duration: 0,
      })

      if (inspiration.imageUrl?.fileList?.length > 0) {
        const mediaSlug = await this.uploadInspirationImage(
          inspiration.imageUrl.fileList[0]
        )

        message.open({
          key: "updateInspiration",
          type: "success",
          content: "Inspirationsbillede uploadet",
        })

        inspiration.imageUrl = mediaSlug
      }

      await api.makeRequest(
        `/admin/gallery/${inspiration?.id}`,
        "PUT",
        inspiration
      )

      message.success({
        content: "Inspiration opdateret",
        key: "updateInspiration",
      })

      return true
    } catch (error) {
      message.error({
        content: "Der skete en fejl under opdatering af inspiration",
        key: "updateInspiration",
      })
      console.error("Failed to update inspiration:", error)
      return false
    }
  }
}

export const inspirationService = new InspirationService()
