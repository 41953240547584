import { message } from "antd";
import { api } from "./API.service";

class LinksService {
  /**
   * Gets all links
   * @returns
   */
  async getLinks() {
    try {
      const response = await api.makeRequest("/admin/recipe-action");
      return response.content;
    } catch (error) {
      console.error("Failed to fetch recipe actions:", error);
      throw error;
    }
  }

  /**
   * Creates a new link
   * @param link
   * @returns
   */
  async createLink(link: any) {
    message.loading({ content: "Opretter link", key: "createLink" });

    try {
      const response = await api.makeRequest(
        "/admin/recipe-action",
        "POST",
        link
      );

      message.success({
        content: "Link oprettet",
        key: "createLink",
      });

      return response.content;
    } catch (error) {
      console.error("Failed to create recipe action:", error);
      message.error({
        content: "Der skete en fejl under oprettelse af link",
        key: "createLink",
      });

      throw error;
    }
  }

  /**
   * Deletes a link
   * @param link
   * @returns
   */
  async deleteLink(linkID: any) {
    message.loading({ content: "Sletter link", key: "deleteLink" });

    try {
      const response = await api.makeRequest(
        `/admin/recipe-action/${linkID}`,
        "DELETE"
      );

      message.success({
        content: "Link slettet",
        key: "deleteLink",
      });

      return response.content;
    } catch (error) {
      console.error("Failed to delete recipe action:", error);
      message.error({
        content: "Der skete en fejl under sletning af link",
        key: "deleteLink",
      });

      throw error;
    }
  }

  async updateLink(link: any) {
    message.loading({ content: "Opdaterer link", key: "updateLink" });

    try {
      const response = await api.makeRequest(
        `/admin/recipe-action/${link?.id}`,
        "PUT",
        link
      );

      message.success({
        content: "Link opdateret",
        key: "updateLink",
      });

      return response.content;
    } catch (error) {
      console.error("Failed to update recipe action:", error);
      message.error({
        content: "Der skete en fejl under opdatering af link",
        key: "updateLink",
      });

      throw error;
    }
  }
}

export const linksService = new LinksService();
