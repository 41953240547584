import { api } from "./API.service";
import { RecipeCategory } from "../Models/RecipeCategory";

class RecipeCategoryService {
  /**
   * Get recipe categories
   * @returns Promise containing an array of recipe categories
   */
  async getRecipeCategories(): Promise<RecipeCategory[]> {
    console.log("Get Recipe Categories");
    try {
      const response = await api.makeRequest("/admin/recipe-category");
      return response.content;
    } catch (error) {
      console.error("Failed to fetch recipe categories:", error);
      throw error;
    }
  }

  /**
   * Create recipe category
   * @param recipeCategory The recipe category to create
   * @returns Promise containing a boolean indicating the success of the creation
   */
  async createRecipeCategory(recipeCategory: RecipeCategory): Promise<boolean> {
    try {
      await api.makeRequest("/admin/recipe-category", "POST", recipeCategory);
      return true;
    } catch (error) {
      console.error("Failed to create recipe category:", error);
      return false;
    }
  }

  /**
   * Update recipe category
   * @param recipeCategory The updated recipe category
   * @param recipeCategories All existing recipe categories
   * @returns Promise containing a boolean indicating the success of the update
   */
  async updateRecipeCategory(
    recipeCategory: RecipeCategory[]
  ): Promise<boolean> {
    try {
      await api.makeRequest(`/admin/recipe-category`, "PATCH", recipeCategory);
      return true;
    } catch (error) {
      console.error("Failed to update recipe category:", error);
      throw error;
    }
  }

  /**
   * Delete recipe category
   * @param recipeCategoryId ID of the recipe category to delete
   * @returns Promise containing a boolean indicating the success of the deletion
   */
  async deleteRecipeCategory(
    recipeCategory: RecipeCategory,
    recipeCategories: RecipeCategory[]
  ): Promise<boolean> {
    const orderToDelete = recipeCategory.order;

    // Update the order of all recipe categories with a higher order than the one to delete
    const recipeCategoriesToUpdate = recipeCategories.filter(
      (recipeCategory) => recipeCategory.order > orderToDelete
    );

    const updatedRecipeCategories = recipeCategoriesToUpdate.map(
      (recipeCategory) => {
        return {
          ...recipeCategory,
          order: recipeCategory.order - 1,
        };
      }
    );

    try {
      await api.makeRequest(
        `/admin/recipe-category/${recipeCategory.id}`,
        "DELETE"
      );
      await this.updateRecipeCategory(updatedRecipeCategories);
      return true;
    } catch (error) {
      console.error(
        `Failed to delete recipe category with ID ${recipeCategory.id}:`,
        error
      );
      return false;
    }
  }
}

export const recipeCategoryService = new RecipeCategoryService();
