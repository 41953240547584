import { v4 as uuidv4 } from "uuid";
import { makeRequest } from "./Axios.service";

class ImageService {
  public makeImageFilename(fileWrapper: any, source?: string): string {
    if (!fileWrapper) {
      return "";
    }

    const { file } = fileWrapper;
    const uuid = uuidv4();
    const extension = file.name.split(".").pop();
    const fileName = `${uuid}.${extension}`;

    return fileName;
  }

  public async convertAndUploadImage(file: any, folder: string) {
    const formData = new FormData();
    formData.append("file", file);

    const convertedMedia = await makeRequest(
      "POST",
      "/admin/media/upload/" + folder,
      formData
    );

    return convertedMedia;
  }
}

export const imageService = new ImageService();
