import {
  BugOutlined,
  BulbOutlined,
  CopyOutlined,
  FormOutlined,
  HomeOutlined,
  LinkOutlined,
  OrderedListOutlined,
  UserOutlined,
} from "@ant-design/icons"
import { Menu, Layout, MenuProps, message } from "antd"
import { useLocation, useNavigate } from "react-router-dom"
import { firebaseService } from "../Services/Firebase.service"

const { Header } = Layout
export function NavigationBar({ isDeveloper }: any) {
  const navigate = useNavigate()
  const location = useLocation()

  const user = firebaseService.auth.currentUser
  // Extract the path from the location object
  const currentPath = location.pathname

  // Function to determine the active menu item key
  const getActiveMenuItem = () => {
    if (currentPath === "/") {
      return "/"
    } else if (currentPath === "/inspiration") {
      return "inspiration"
    } else if (currentPath === "/recipes") {
      return "recipes"
    } else if (currentPath === "/ingredients") {
      return "ingredients"
    } else if (currentPath === "/recipe-categories") {
      return "recipe-categories"
    } else if (currentPath === "/meal-categories") {
      return "meal-categories"
    } else if (currentPath === "/developer" && isDeveloper) {
      return "developer"
    } else if (currentPath === "/articles") {
      return "articles"
    }
    return "" // Return an empty string for no active item
  }

  const menuItems: MenuProps["items"] = [
    { key: "/", icon: <HomeOutlined />, label: "Hjem" },
    {
      key: "recipes-group",
      icon: <FormOutlined />,
      label: "Opskrifter",
      children: [
        {
          key: "recipes",
          icon: <FormOutlined />,
          label: "Opskrifter",
        },
        {
          key: "ingredients",
          icon: <OrderedListOutlined />,
          label: "Ingredienser",
        },
        {
          key: "inspiration",
          icon: <BulbOutlined />,
          label: "Måltidsinspiration",
        },
        {
          key: "links",
          icon: <LinkOutlined />,
          label: "Links",
        },
        {
          key: "ingredient-units",
          icon: <OrderedListOutlined />,
          label: "Ingrediensenheder",
        },
        {
          key: "recipe-categories",
          icon: <CopyOutlined />,
          label: "Opskriftskategorier",
        },
        {
          key: "meal-categories",
          icon: <CopyOutlined />,
          label: "Måltidskategorier",
        },
      ],
    },
    {
      key: "articles",
      icon: <CopyOutlined />,
      label: "Artikler",
    },
    {
      key: "terms-group",
      icon: <CopyOutlined />,
      label: "Administrativt",
      children: [
        {
          key: "terms",
          icon: <CopyOutlined />,
          label: "Vilkår",
        },
      ],
    },
  ]

  menuItems.push({
    key: "user",
    icon: <UserOutlined />,
    label: user?.displayName,
    children: [
      {
        key: "sign-out",
        label: "Log ud",
        onClick: () => {
          firebaseService.auth.signOut();
          message.success("Du er blevet logget ud");
        },
      }
    ],
  })

  const onClick: MenuProps["onClick"] = (e) => {
    navigate(e.key)
  }

  return (
    <Header style={{ position: "sticky", top: 0, zIndex: 1, width: "100%" }}>
      <div
        style={{
          float: "left",
          width: 120,
          height: 31,
          margin: "16px 24px 16px 0",
          background: "rgba(255, 255, 255, 0.2)",
        }}
      />
      <Menu
        theme="dark"
        mode="horizontal"
        onClick={onClick}
        selectedKeys={[getActiveMenuItem()]}
        items={menuItems}
      ></Menu>
    </Header>
  )
}
