import { message } from "antd"
import { api } from "./API.service"
import { imageService } from "./Image.service"

export interface ArticleCategory {
  id: number
  title: string
  order: number
  numberOfArticles?: number
}

// interface Article {}

class ArticleService {
  /**
   * Get all articles
   * @returns Promise containing all articles
   */
  async getArticleCategories(): Promise<ArticleCategory[]> {
    try {
      const response = await api.makeRequest("/admin/article-category")
      return response.content
    } catch (error) {
      throw error
    }
  }

  async createArticle(article: any, imageChanged: boolean) {
    let headerFileName: string = ""

    let errorCode = 0

    try {
      message.open({
        key: "createArticle",
        type: "loading",
        content: "Opretter artikel...",
        duration: 0,
      })

      if (article.headerImageURL?.fileList?.length > 0) {
        message.open({
          key: "createArticle",
          type: "loading",
          content: "Uploader header billede...",
          duration: 0,
        })

        const mediaSlug = await imageService.convertAndUploadImage(
          article.headerImageURL.fileList[0].originFileObj,
          "article"
        )

        errorCode = 1

        if (mediaSlug) {
          headerFileName = mediaSlug
        }

        article.headerImageURL = imageChanged
          ? headerFileName
          : article.headerImageURL ?? ""

        errorCode = 2

        await api.makeRequest("/admin/article", "POST", article)

        message.success({
          key: "createArticle",
          content: "Artiklen blev oprettet!",
          duration: 5,
        })

        return true
      } else {
        delete article.headerImageURL

        errorCode = 3

        await api.makeRequest("/admin/article", "POST", article)

        message.success({
          key: "createArticle",
          content: "Artiklen blev oprettet!",
          duration: 5,
        })

        return true
      }
    } catch (error) {
      console.error("Failed to create article:", error)

      message.open({
        key: "createArticle",
        type: "error",
        content: `Der skete desværre en fejl under oprettelse af artiklen 😢 [RS:#${errorCode}]`,
        duration: 10,
      })
      return false
    }
  }

  async getArticles() {
    try {
      const response = await api.makeRequest("/admin/article?drafts=true")
      return response.content
    } catch (error) {
      throw error
    }
  }

  async updateArticle(article: any, imageChanged: boolean) {
    let headerFileName: string = ""

    article.articleCategory = article.articleCategory?.id
      ? article.articleCategory?.id
      : article.articleCategory

    let errorCode = 0

    try {
      message.open({
        key: "updateArticle",
        type: "loading",
        content: "Opdaterer artikel...",
        duration: 0,
      })

      if (imageChanged && article.headerImageURL?.fileList?.length > 0) {
        message.open({
          key: "updateArticle",
          type: "loading",
          content: "Uploader header billede...",
          duration: 0,
        })

        const mediaSlug = await imageService.convertAndUploadImage(
          article.headerImageURL.fileList[0].originFileObj,
          "article"
        )

        errorCode = 1

        if (mediaSlug) {
          headerFileName = mediaSlug
        }

        article.headerImageURL = headerFileName

        errorCode = 2

        await api.makeRequest(`/admin/article/${article?.id}`, "PUT", article)

        message.success({
          key: "updateArticle",
          content: "Artiklen blev opdateret!",
          duration: 5,
        })

        return true
      } else {
        if (imageChanged) {
          delete article.headerImageURL
        }

        errorCode = 3

        await api.makeRequest(`/admin/article/${article?.id}`, "PUT", article)

        message.success({
          key: "updateArticle",
          content: "Artiklen blev opdateret!",
          duration: 5,
        })

        return true
      }
    } catch (error) {
      console.error("Failed to update article:", error)

      message.open({
        key: "updateArticle",
        type: "error",

        content: `Der skete desværre en fejl under opdatering af artiklen 😢 [RS:#${errorCode}]`,
        duration: 10,
      })
      return false
    }
  }

  async deleteArticle(articleId: string) {
    message.open({
      key: "deleteArticle",
      type: "loading",
      content: "Sletter artikel...",
      duration: 0,
    })

    try {
      await api.makeRequest(`/admin/article/${articleId}`, "DELETE")

      message.success({
        key: "deleteArticle",
        content: "Artiklen blev slettet!",
        duration: 5,
      })

      return true
    } catch (error) {
      console.error("Failed to delete article:", error)

      message.open({
        key: "deleteArticle",
        type: "error",
        content: "Der skete desværre en fejl under sletning af artiklen 😢",
        duration: 10,
      })
      return false
    }
  }

  /**
   * Create article category
   * @param articleCategory The article category to create
   */
  async createArticleCategory(articleCategory: ArticleCategory) {
    try {
      await api.makeRequest("/admin/article-category", "POST", articleCategory)
      return true
    } catch (error) {
      console.error("Failed to create article category:", error)
      return false
    }
  }

  /**
   * Update article category
   * @param articleCategory The updated article category
   */
  async updateArticleCategory(articleCategory: ArticleCategory) {
    try {
      await api.makeRequest(`/admin/article-category?drafts=true`, "PATCH", [
        articleCategory,
      ])
      return true
    } catch (error) {
      console.error("Failed to update article category:", error)
      return false
    }
  }

  /**
   * Delete article category
   * @param articleCategoryId ID of the article category to delete
   */
  async deleteArticleCategory(articleCategoryID: number) {
    try {
      await api.makeRequest(
        `/admin/article-category/${articleCategoryID}`,
        "DELETE"
      )
      return true
    } catch (error) {
      console.error("Failed to delete article category:", error)
      return false
    }
  }
}

export const articleService = new ArticleService()
