import { Form, Input, Button, message } from "antd";
import { firebaseService } from "../Services/Firebase.service";
import { useState } from "react";

export const LoginPage = () => {
  const [isLoading, setIsLoading] = useState(false);

  async function onFinish(values: any) {
    setIsLoading(true);

    const { user, error }: any = await firebaseService.login(
      values.email,
      values.password
    );

    if (user) {
      message.success("Du er nu logget ind");
    }

    setIsLoading(false);
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Form
        name="login"
        initialValues={{ remember: false }}
        onFinish={onFinish}
        style={{ maxWidth: "400px" }}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: "Email addresse er ugyldig" },
            {
              pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              message: "Indtast venligst en gyldig email addresse",
            },
          ]}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input style={{ width: 250 }} />
        </Form.Item>

        <Form.Item
          label="Adgangskode"
          name="password"
          rules={[{ required: true, message: "Adgangskode kan ikke være tom" }]}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input.Password style={{ width: 250 }} />
        </Form.Item>

        <Form.Item wrapperCol={{ span: 24 }}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Log in
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
