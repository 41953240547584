import { UploadOutlined } from "@ant-design/icons"
import { Button, Col, Form, Input, Modal, Popconfirm, Row, Upload } from "antd"
import { useForm } from "antd/es/form/Form"
import { useEffect, useRef, useState } from "react"
import { inspirationService } from "../Services/Inspiration.service"
import { hostedImage } from "../Helpers/Image.helper"
import { bytesToKilobytes } from "../Helpers/File.helper"

export const InspirationModal = ({ isShowing, onClose, inspiration }: any) => {
  const inspirationFormRef: any = useRef({})
  const [inspirationForm] = useForm()
  const [imageChanged, setImageChanged] = useState<boolean>(false)

  const defaultImageURL =
    (inspiration?.imageUrl ?? "").length > 0
      ? [
          {
            url: hostedImage(inspiration?.imageUrl ?? "", "gallery"),
            uid: "gallery1",
            name: "",
          },
        ]
      : []

  useEffect(() => {
    if (inspiration?.id) {
      inspirationForm.setFieldsValue(inspiration)
    } else {
      inspirationForm.resetFields()
    }
  }, [inspiration])

  async function confirmDeletion() {
    await inspirationService.deleteInspiration(inspiration.id)
    resetModal()
    onClose()
  }

  function resetModal() {
    inspirationForm.resetFields()
  }

  async function saveInspiration(values: any) {
    let success = false

    try {
      if (values.id) {
        success = await inspirationService.updateInspiration(values)
      } else {
        success = await inspirationService.createInspiration(
          values,
          imageChanged
        )
      }

      if (success) {
        onClose()
      }
    } catch (error) {}
  }

  async function handleBeforeUpload(key: string, event?: any) {
    setImageChanged(true)
    return false
  }

  return (
    <Modal
      open={isShowing}
      title={inspiration?.id ? "Opdater" : "Opret"}
      width={700}
      onCancel={onClose}
      onOk={() => inspirationFormRef.current.submit()}
    >
      <Form
        layout="vertical"
        form={inspirationForm}
        ref={inspirationFormRef}
        onFinish={saveInspiration}
        autoComplete="off"
      >
        <Form.Item name="id" hidden={true}>
          <Input />
        </Form.Item>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="title"
              label="Titel"
              rules={[{ required: true, message: "Feltet skal udfyldes" }]}
            >
              <Input placeholder="Indtast titel" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="imageUrl"
              label="Billede"
              rules={[{ required: true, message: "Feltet skal udfyldes" }]}
            >
              <Upload
                listType="picture"
                maxCount={1}
                beforeUpload={() => handleBeforeUpload("imageUrl")}
                onChange={(e) => handleBeforeUpload("imageUrl", e)}
                defaultFileList={defaultImageURL}
              >
                <Button icon={<UploadOutlined />}>Upload (Max: 1)</Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Eksternt link" name="url">
              <Input placeholder="Valgfrit link" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="description"
              label="Catchphrase"
              rules={[{ required: true, message: "Feltet skal udfyldes" }]}
            >
              <Input.TextArea placeholder="Indtast catchphrase" />
            </Form.Item>
          </Col>
        </Row>
        {inspiration?.id && (
          <Row style={{ marginTop: 40 }}>
            <Col span={24}>
              <Popconfirm
                title="Slet inspiration"
                description="Vil du slette denne inspiration?"
                onConfirm={confirmDeletion}
                onCancel={() => {}}
                okText="Ja"
                cancelText="Nej"
              >
                <Button danger type="dashed">
                  Slet inspiration
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        )}
      </Form>
    </Modal>
  )
}
