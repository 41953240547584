import { useEffect, useState } from "react";
import { useRecipeStore } from "../State/Recipe.store";
import { recipeService } from "../Services/Recipe.service";
import { userService } from "../Services/User.service";
import { Col, Descriptions, Divider, Row } from "antd";
import { UserStatus } from "../Models/UserStatus";
import { defaults } from "lodash";

export function HomePage() {
  const { recipes, setRecipes } = useRecipeStore();

  const [ userStatus , setUserStatus] = useState<UserStatus>({
    admin: 0,
    dev: 0,
    inactive: 0,
    regular: 0,
    total: 0
  });

  useEffect(() => {
    fetchRecipes();
    fetchUserStatus();
  }, []);

  async function fetchRecipes() {
    const recipeList = await recipeService.getRecipes();
    setRecipes(recipeList);
  }

  async function fetchUserStatus() {
    const userStatus: UserStatus = await userService.getUserStatus();
    if(userStatus != undefined) {
      setUserStatus(userStatus);
    }
  }

  const totalRecipesFavorites = () => {
    let total = 0;

    recipes.forEach((recipe) => {
      total += recipe.favorites;
    });

    return total;
  };

  const mostFavoritedRecipe = () => {
    let mostFavorited = 0;
    let mostFavoritedRecipe = "";

    recipes.forEach((recipe) => {
      if (recipe.favorites > mostFavorited) {
        mostFavorited = recipe.favorites;
        mostFavoritedRecipe = recipe.title;
      }
    });

    return mostFavoritedRecipe;
  };

  
  return (
    <>
      <Row>
        <Col span={16}>
          <Descriptions title="Opskrifter" layout="vertical">
            <Descriptions.Item label="Opskrifter">
              {recipes.length}
            </Descriptions.Item>
            <Descriptions.Item label="Favoritter i alt">
              {totalRecipesFavorites()}
            </Descriptions.Item>
            <Descriptions.Item label="Mest populære opskrift">
              {mostFavoritedRecipe()}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
      <Row>
        <Col span={16}>
          <Descriptions title="Brugere" layout="vertical">
            <Descriptions.Item label="Aktive Brugere">
              {userStatus.regular}
            </Descriptions.Item>
            <Descriptions.Item label="Inaktive Brugere">
              {userStatus.inactive}
            </Descriptions.Item>
            <Descriptions.Item label="I alt">
              {userStatus.total}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
      <Divider />
    </>
  );
}
