import { useEffect, useRef, useState } from "react";
import { ArticleCategory, articleService } from "../Services/Article.service";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Image,
  Modal,
  Radio,
  Row,
  Space,
  Table,
  message,
  Descriptions,
} from "antd";
import { useForm } from "antd/es/form/Form";
import { ArticleModal } from "./ArticleModal";
import { CheckOutlined, CloseSquareOutlined } from "@ant-design/icons";
import { ArticleCreateModal } from "./ArticleCreateModal";

export function ArticlesPage(): JSX.Element {
  const createCategoryForm: any = useRef({});
  const updateCategoryForm: any = useRef({});

  const [activeArticle, setActiveArticle] = useState<any>(null);
  const [filterQuery, setFilterQuery] = useState("");
  const [viewFilter, setViewFilter] = useState<string>("all");
  const [otherFilter, setOtherFilter] = useState<string>("all");

  function resetFilters() {
    setViewFilter("all");
    setOtherFilter("all");
  }

  const [isShowingCreateCategoryModal, setIsShowingCreateCategoryModal] =
    useState<boolean>(false);
  const [isShowingEditCategoryModal, setIsShowingEditCategoryModal] =
    useState<number>();

  const [triggerRefetch, setTriggerRefetch] = useState<number>(0);

  const [articleCategoryForm] = useForm();

  /**
   * Create article
   */
  const [isShowingArticleModal, setIsShowingArticleModal] =
    useState<boolean>(false);

  const [isShowingArticleCreateModal, setIsShowingArticleCreateModal] =
    useState<boolean>(false);

  function openCreateModal() {
    setActiveArticle(null);
    setIsShowingCreateCategoryModal(true);
  }

  function closeCreateModal() {
    setIsShowingCreateCategoryModal(false);
    setActiveArticle(null);
  }

  function editCategoryModal(category: any) {
    setIsShowingEditCategoryModal(category.id);
    articleCategoryForm.setFieldsValue(category);
  }

  function closeCategoryModal() {
    setIsShowingEditCategoryModal(undefined);
    articleCategoryForm.resetFields();
  }

  async function handleCategoryFinish(values: any) {
    if (values.id) {
      const result = await articleService.updateArticleCategory(values);

      if (result) {
        closeCategoryModal();
        setTriggerRefetch(triggerRefetch + 1);
      } else {
        message.error("Der skete en fejl ved opdatering af kategori");
      }
    } else {
      const result = await articleService.createArticleCategory(values);
      if (result) {
        closeCreateModal();
        setTriggerRefetch(triggerRefetch + 1);
      } else {
        message.error("Der skete en fejl ved oprettelse af kategori");
      }
    }
  }

  async function handleCategoryCreateAction() {
    const form = createCategoryForm.current;
    form.submit();
  }

  async function handleCategoryEditAction() {
    const form = updateCategoryForm.current;
    form.submit();
  }

  async function deleleteCategory() {
    const result = await articleService.deleteArticleCategory(
      isShowingEditCategoryModal!
    );

    if (result) {
      closeCategoryModal();
      setTriggerRefetch(triggerRefetch + 1);
    } else {
      message.error("Der skete en fejl ved sletning af kategori");
    }
  }

  /**
   * CREATE ARTICLE
   */
  function openCreateArticleModal(article?: any) {
    if (article?.id) {
      setActiveArticle(article);
      setIsShowingArticleModal(true);
    } else {
      setIsShowingArticleCreateModal(true);
    }
  }

  function closeArticleModal() {
    setIsShowingArticleModal(false);
    setIsShowingArticleCreateModal(false);
    setActiveArticle(undefined);
    setTriggerRefetch(triggerRefetch + 1);

    window.location.reload();
  }

  const handleFilterQuerychange = (event: any) => {
    setFilterQuery(event.target.value);
  };

  return (
    <div>
      <Row>
        <Col span={16}>
          <Row style={{ width: "100%", flexGrow: 1 }}>
            <Space>
              <Col span={3}>
                <Button onClick={openCreateModal}>Opret Artikelkategori</Button>
              </Col>

              <Col span={3}>
                <Button type="primary" onClick={openCreateArticleModal}>
                  Opret Artikel
                </Button>
              </Col>
            </Space>
          </Row>
        </Col>

        <Col
          span={8}
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.05)",
            padding: 14,
            borderRadius: 4,
          }}
        >
          <Row style={{ width: "100%", flexGrow: 1 }}>
            <Space direction="vertical">
              <Col span={24}>
                <h4>Visningsmuligheder</h4>
              </Col>
              <Col span={24}>
                <label style={{ opacity: 0.5, paddingBottom: "5px" }}>
                  (søg på titel ELLER indhold)
                </label>
                <Input
                  placeholder="Find artikel"
                  value={filterQuery}
                  onChange={handleFilterQuerychange}
                  style={{ width: "100%", flexGrow: 1 }}
                />
              </Col>

              <Radio.Group
                value={viewFilter}
                onChange={(e) => setViewFilter(e.target.value)}
              >
                <Radio.Button value="all">Alle</Radio.Button>
                <Radio.Button value="free">Gratis</Radio.Button>
                <Radio.Button value="premium">Premium</Radio.Button>
              </Radio.Group>

              <Col span={24} style={{ marginTop: 16 }}>
                <h4>Øvrige filtre</h4>
              </Col>

              <Radio.Group
                value={otherFilter}
                onChange={(e) => setOtherFilter(e.target.value)}
                style={{ marginBottom: 16 }}
              >
                <Space direction="vertical">
                  <Radio value="all">Alle</Radio>
                  <Radio value="missing-image">Mangler billede</Radio>
                  <Radio value="draft">Kladde</Radio>
                </Space>
              </Radio.Group>

              <Button onClick={resetFilters}>Nulstil filtre</Button>
            </Space>
          </Row>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={7} style={{ flexGrow: 1, width: "100%" }}>
          <ArticlesCategoriesTable
            onEdit={editCategoryModal}
            triggerRefetch={triggerRefetch}
          />
        </Col>
        <Col span={16} offset={1} style={{ flexGrow: 1, width: "100%" }}>
          <ArticlesTable
            triggerRefetch={triggerRefetch}
            onEdit={openCreateArticleModal}
            filter={filterQuery}
            viewFilter={viewFilter}
            otherFilter={otherFilter}
          />
        </Col>
      </Row>

      <Modal
        title="Opdater Artikelkategori"
        open={isShowingEditCategoryModal !== undefined}
        onCancel={closeCategoryModal}
        onOk={handleCategoryEditAction}
        width={700}
        footer={[
          <Row key="recipe-categories-update-footer">
            <Button
              key="delete"
              danger
              onClick={() => {
                Modal.confirm({
                  title: "Er du sikker på du vil slette denne kategori?",
                  content: "Denne handling kan ikke fortrydes",
                  okText: "Slet",
                  cancelText: "Fortryd",
                  onOk: deleleteCategory,
                });
              }}
            >
              Slet
            </Button>
            <span key="spacer" style={{ flex: 1 }} />
            <Button key="cancel" onClick={closeCategoryModal}>
              Fortryd
            </Button>
            <Button
              key="submit"
              type="primary"
              onClick={handleCategoryEditAction}
            >
              Gem
            </Button>
          </Row>,
        ]}
      >
        <Form
          layout="vertical"
          onFinish={handleCategoryFinish}
          autoComplete="off"
          form={articleCategoryForm}
          ref={updateCategoryForm}
        >
          <Row gutter={16}>
            <Form.Item name="id" hidden={true}>
              <Input />
            </Form.Item>

            <Col span={12}>
              <Form.Item
                name="title"
                label="Titel"
                rules={[{ required: true, message: "Feltet skal udfyldes" }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        title="Opret artikelkategori"
        open={isShowingCreateCategoryModal}
        onCancel={closeCreateModal}
        onOk={handleCategoryCreateAction}
        width={700}
      >
        <Form
          layout="vertical"
          onFinish={handleCategoryFinish}
          autoComplete="off"
          form={articleCategoryForm}
          ref={createCategoryForm}
        >
          <Row gutter={16}>
            <Form.Item name="id" hidden={true}>
              <Input />
            </Form.Item>

            <Col span={12}>
              <Form.Item
                name="title"
                label="Titel"
                rules={[{ required: true, message: "Feltet skal udfyldes" }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      <ArticleModal
        isShowing={isShowingArticleModal}
        onClose={closeArticleModal}
        article={activeArticle}
      />

      <ArticleCreateModal
        isShowing={isShowingArticleCreateModal}
        onClose={closeArticleModal}
      />
    </div>
  );
}

function ArticlesTable({
  triggerRefetch,
  onEdit,
  filter,
  viewFilter,
  otherFilter,
}: any): JSX.Element {
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [articles, setArticles] = useState<any[]>([]);
  const [filteredArticles, setFilteredArticles] = useState<any[]>([]);

  useEffect(() => {
    fetchArticles();
  }, [triggerRefetch]);

  useEffect(() => {
    // Filter articles based on the criteria
    const filtered = articles.filter((article: any) => {
      const titleMatches = article.title
        .toLowerCase()
        .includes(filter.toLowerCase());
      let viewFilterMatches = false;
      let otherFilterMatches = false;

      if (viewFilter === "all") {
        viewFilterMatches = true;
      } else if (viewFilter === "free") {
        viewFilterMatches = article.premium === false;
      } else if (viewFilter === "premium") {
        viewFilterMatches = article.premium === true;
      }

      if (otherFilter === "all") {
        otherFilterMatches = true;
      } else if (otherFilter === "missing-image") {
        otherFilterMatches = !article.headerImageURL;
      } else if (otherFilter === "draft") {
        otherFilterMatches = article.draft === true;
      }

      return titleMatches && viewFilterMatches && otherFilterMatches;
    });

    setFilteredArticles(filtered);
  }, [filter, articles, viewFilter, otherFilter]);

  async function fetchArticles() {
    setIsFetching(true);
    let result = await articleService.getArticles();
    setIsFetching(false);

    if (result) {
      setArticles(result);
    }
  }

  /**
   * Article categories table
   */
  const columns = [
    {
      title: "Billede",
      dataIndex: "headerImageURL",
      align: "center" as any,
      width: 80,
      key: "headerImageURL",
      render: (headerImageURL: string) =>
        headerImageURL?.length > 0 ? (
          <Image src={headerImageURL} />
        ) : (
          <CloseSquareOutlined />
        ),
    },
    {
      title: "Titel",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Kategori",
      dataIndex: "articleCategories",
      key: "articleCategories",
      render: (articleCategories: any) =>
        articleCategories.map((item: any) => item.title).join(", "),
    },
    {
      title: "Premium",
      dataIndex: "premium",
      key: "premium",
      align: "center" as any,
      width: 120,
      render: (premium: boolean) => (premium ? <CheckOutlined /> : ""),
    },
    {
      title: "Kladde",
      dataIndex: "draft",
      key: "draft",
      align: "center" as any,
      width: 80,
      render: (draft: boolean) => (draft ? <CheckOutlined /> : ""),
    },
  ];

  const freeArticles = articles.filter((article) => !article.premium);
  const premiumArticles = articles.filter((article) => article.premium);

  return (
    <>
      <Row>
        <Descriptions title="Artikler" layout="vertical">
          <Descriptions.Item label="Artikler i alt">
            {articles?.length}
          </Descriptions.Item>
          <Descriptions.Item label="Gratis artikler">
            {freeArticles?.length}
          </Descriptions.Item>
          <Descriptions.Item label="Premium artikler">
            {premiumArticles?.length}
          </Descriptions.Item>
        </Descriptions>
      </Row>
      <Row>
        <Col span={24}>
          <h1>Artikler</h1>
          <Table
            dataSource={filteredArticles}
            columns={columns}
            loading={isFetching}
            onRow={(record, rowIndex) => ({
              onClick: () => {
                onEdit(record);
              },
            })}
          />
        </Col>
      </Row>
    </>
  );
}

const ArticlesCategoriesTable: any = ({ onEdit, triggerRefetch }: any) => {
  /**
   * States
   */
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [articleCategories, setArticleCategories] = useState<ArticleCategory[]>(
    []
  );

  /**
   * Fetch article categories on mount
   */
  useEffect(() => {
    fetchArticleCategories();
  }, [triggerRefetch]);

  /**
   * Fetch article categories
   * @returns {Promise<void>}
   */
  async function fetchArticleCategories() {
    setIsFetching(true);

    try {
      const articleCategories: ArticleCategory[] =
        await articleService.getArticleCategories();

      setArticleCategories(articleCategories);
      setIsFetching(false);
    } catch (error) {
      setIsFetching(false);
    }
  }

  /**
   * Article categories table
   */
  const columns = [
    {
      title: "Titel",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Artikler",
      dataIndex: "numberOfArticles",
      key: "numberOfArticles",
    },
  ];

  return (
    <Col span={24}>
      <h1>Artikelkategorier</h1>
      <Table
        dataSource={articleCategories}
        columns={columns}
        loading={isFetching}
        pagination={{
          defaultPageSize: 100,
          pageSize: 100,
        }}
        onRow={(record, rowIndex) => ({
          onClick: () => {
            onEdit(record);
          },
        })}
      />
    </Col>
  );
};
